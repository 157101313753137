import React from "react"
import { Container, Nav, Navbar, NavDropdown, Form, Button } from "react-bootstrap"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { AuthProvider } from "../contexts/AuthContext"

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "./Login";
import Signup from "./Signup";
import NotFound from "./NotFound";
import AccountDashboard from "./AccountDashboard";

function App() {

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container fluid>
          <Navbar.Brand href="/">乐源开放平台</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link href="/">首页</Nav.Link>
              <Nav.Link href="/posts">我的动态</Nav.Link>
              <Nav.Link href="/events">我的活动</Nav.Link>
              <Nav.Link href="/create_article">我的经验</Nav.Link>
              <Nav.Link href="/editor">写文章</Nav.Link>
              <NavDropdown title="关于乐源" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://www.leyuanreview.com/privacy">隐私政策</NavDropdown.Item>
                <NavDropdown.Item href="https://www.leyuanreview.com/privacy">使用条款</NavDropdown.Item>
                <NavDropdown.Item href="https://www.leyuanreview.com/community">社区规范</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
        
      </Navbar>

    <AuthProvider>
    <Routes>

    <Route element={<PrivateRoute />}>
      <Route path="/dashboard" element={<AccountDashboard />} />
    </Route>
    <Route element={<PublicRoute />}>
      <Route exact path="/" element={<Login/>} />
    </Route>
      
      <Route path="/signup" element={<Signup/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/404" element={<NotFound/>}/>
      <Route path="*" element={<NotFound/>} />
    </Routes>
    </AuthProvider>

    
    </div>
  );

}

export default App;
