import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, Navbar, ListGroup, DropdownButton, Dropdown } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { auth, fires} from "../firebase"
import { Link, useNavigate, Route, useParams, Navigate } from "react-router-dom"
import Sidebar from "./SideBar"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';




export default function AccountDashboard() {
  const [error, setError] = useState("")
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth()
  


  const [posts, setPosts] = useState([]);
  const [viewTys, setViewTys] = useState([]);
  const [orgLinks, setOrgLinks] = useState([]);
  const [orgFeatures, setOrgFeatures] = useState([]);
  const [orgQuotes, setOrgQuotes] = useState([]);
  const [orgPosts, setOrgPosts] = useState([]);
  const [orgExps, setOrgExps] = useState([]);

  const maxW = isMobile ? "100%" : "100%"

  var id = currentUser.uid;

  const routeChangeProfile = () =>{ 
    let path = '/update-profile' ; 
    window.location.href = path;
  }
  const routeChangeGroupInfo = () =>{ 
    let path = '/update-group' ; 
    window.location.href = path;
  }
  const routeToDataAnalytic= () =>{ 
    let path = '/data-analytic' ; 
    window.location.href = path;
  }
  const routeToQuotes=() => {
    let path = "/group-quotes"
    window.location.href = path;
  }
  const routeToTeams= () =>{ 
    let path = '/teams' ; 
    window.location.href = path;
  }
  const routeToCreateExp= () =>{ 
    let path = '/create-exp' ; 
    window.location.href = path;
  }
  const routeToExps = () =>{ 
    let path = '/experiences' ; 
    window.location.href = path;
  }
  const routeToCreatePost= () =>{ 
    let path = '/create-post' ; 
    window.location.href = path;
  }
  const routeToPost = () =>{ 
    let path = '/posts' ; 
    window.location.href = path;
  }
  const routeToCreateEvent = () =>{ 
    let path = '/create-event' ; 
    window.location.href = path;
  }
  const routeToEvents = () =>{ 
    let path = '/events' ; 
    window.location.href = path;
  }
  
  const routeToLogout= () =>{ 
    logout()
    let path = '/' ; 
    window.location.href = path;
  }

  const handleActionSelect=(e)=>{
    if (e == 'logout'){
      routeToLogout()
    }
    else if (e == "account-modify"){
      routeChangeProfile()
    }
  }

  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }


  useEffect(() => {
      return fires.collection('users').doc(id).get().then((snapshot) => {
          if (snapshot.exists) {
              const users = [];
              const userData = snapshot.data();
              var orgId = userData.orgId;
              const uid = id;
              fires.collection('groups').doc(orgId).get().then((snapshotA) => {
                if (snapshotA.exists){
                  const posts = [];
                  const postData = snapshotA.data();

                  posts.push({ ...postData, id: orgId })
                  setPosts(posts)
                  if (postData.contacts != null) {
                    setOrgLinks(postData.contacts)
                  }
                  if (postData.quotes != null){
                    setOrgQuotes(postData.quotes)
                  }
                  if (postData.features != null) {
                    setOrgFeatures(postData.features)
                  }
                  fires.collection('posts').where('orgId','==',orgId).orderBy('timestamp','desc').get().then((snapshotB) => {
                    var orgPosts = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgPosts.push({ ...contentData, id: contentId })
                    })
                    setOrgPosts(orgPosts)
                })
                fires.collection('exps').where('orgId','==',orgId).orderBy('timestamp','desc').get().then((snapshotB) => {
                    var orgExps = [];
                    snapshotB.docs.forEach((doc) => {
                      const contentId = doc.id;
                      const contentData = doc.data();
                      orgExps.push({ ...contentData, cid: contentId })
                    })
                    setOrgExps(orgExps)
                })
                  fires.collection('groups').doc(orgId).collection('views').get().then((snapshotB) => {
                    var allViews = [];
                    var viewTypes = [];

                    var todayWeb = 0
                    var todayMobile = 0
                    var todayLink = 0
                    snapshotB.docs.forEach((doc) => {
                      const viewId = doc.id;
                      const viewData = doc.data();
                      allViews.push({ ...viewData, vid: viewId })
                    })
                    allViews.forEach((vItm) => {
                      const vItmDate = vItm.timestamp.toDate()
                      const vURL = vItm.linkId
                      if (isToday(vItmDate) && vItm.platform == 'web' && vItm.type == 'page'){
                          todayWeb += 1
                      }
                      if (isToday(vItmDate) && vItm.platform == 'mobile' && vItm.type == 'page'){
                        todayMobile += 1
                      }
                      if (isToday(vItmDate) && vItm.type == 'link'){
                        todayLink += 1
                      }
                    })
                    viewTypes.push(todayWeb)
                    viewTypes.push(todayMobile)
                    viewTypes.push(todayLink)
                    setViewTys(viewTypes)

                  })

                  document.title = "乐源 LETJOY"
                  document.description = "开启你的海外生活"
                }
                else{
                    routeToLogout()
                }
            })
          }
          else{
              //deleted
              posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
              setPosts(posts)
              document.title = "乐源 LETJOY"
              document.description = "开启你的海外生活"
              routeToLogout()
          }
      });
    }, []);

    
  
  return (
      <div>
          
      {
      posts.map((post) => {

      var error = post.error;
      var coverImgURL = ""
      var profileURL = ""
      var professionalType = ""
      var promoStr = ""
      var orgContacts = []
      if (post.coverURL == null){
          coverImgURL = "url(https://images.unsplash.com/photo-1487088678257-3a541e6e3922?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80)"
      }
      else{
          coverImgURL = "url(" + post.coverURL + ")"
      }
      if (post.promo != null){
        promoStr = post.promo
      }
      profileURL = post.profileURL
      professionalType = post.typeCN

      
      if (post.contacts != null) {
          orgContacts = post.contacts
      }
      
      if (error == null) {
        return <>
        <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          
          <div className="w-100" style={{ maxWidth: maxW, marginTop: '25px', marginBottom: '25px' }}>
          
          <Card className="border-0">
          <Card.Body className = "align-items-center justify-content-center">
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <Card.Title className="text-center" style={{ fontSize: "45px", fontWeight:'bold', color: "#000000"}}>账户中心</Card.Title>
              <DropdownButton
                  alignRight
                  title="我的账户"
                  id="dropdown-menu-align-right"
                  variant="outline-dark"
                  onSelect={handleActionSelect}
                >
                  <Dropdown.Item eventKey='account-modify'>修改账户信息</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='logout' style={{color: 'red'}}>退出</Dropdown.Item>
                </DropdownButton>
            </ListGroup.Item>

            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#data" style={{marginTop: '50px'}}>
      <Row  style={{marginTop: '120px'}}>
        <Col sm={3}>
          <ListGroup variant="dark">
            <ListGroup.Item action href="#data">
              今日数据
            </ListGroup.Item>
            <ListGroup.Item action href="#showdata">
              网页展示
            </ListGroup.Item>
            <ListGroup.Item action href="#account">
              账户信息
            </ListGroup.Item>
            <ListGroup.Item action href="#company">
              公司信息
            </ListGroup.Item>
            <ListGroup.Item action href="#team">
              我的团队
            </ListGroup.Item>
            <ListGroup.Item action href="#links">
              我的链接
            </ListGroup.Item>
            <ListGroup.Item action href="#posts">
              我的动态
            </ListGroup.Item>
            <ListGroup.Item action href="#experiences">
              我的文章
            </ListGroup.Item>
            <ListGroup.Item action href="#events">
              我的活动
            </ListGroup.Item>
            <ListGroup.Item action href="#quotes">
              我的报价
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col sm={8}>
          <Tab.Content>

            <Tab.Pane eventKey="#account">
            <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>账户信息</Card.Text>
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold">电子邮箱</div>
              <div className="fw-bold">{auth.currentUser.email}</div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold">密码</div>
              <div className="fw-bold">******</div>
            </ListGroup.Item>
          </ListGroup>
          <Button className="w-100 mt3" style={{alignItems:'center', 
          justifyContent:'space-between', 
          backgroundColor:'#000000', 
          borderRadius:'25px', 
          borderColor:'#ffffff', 
          borderWidth:'3px', 
          fontSize: '20px', 
          fontWeight:'bold', 
          height: '50px', 
          marginTop: "25px", 
          marginBottom: "15px"}} onClick={()=> routeChangeProfile() }>修改账户信息</Button>
          
            </Tab.Pane>

            <Tab.Pane eventKey="#company">
              
            <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>公司信息</Card.Text>
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold">公司名称</div>
              <div className="fw-bold">{post.name}</div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold">公司ID</div>
              <div className="fw-bold">{post.id}</div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold">公司类型</div>
              <div className="fw-bold">{post.typeCN}</div>
            </ListGroup.Item>
          </ListGroup>
          <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeChangeGroupInfo() }>修改公司信息</Button>
          

            </Tab.Pane>
            <Tab.Pane eventKey="#data">

            <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>今日数据</Card.Text>
            <ListGroup horizontal fill>
            <ListGroup.Item className="align-items-start">
              <div className="fw-bold text-center">今日网页总点击</div>
              <div className="fw-bold text-center">{viewTys[0]}</div>
            </ListGroup.Item>
            <ListGroup.Item className="align-items-start">
              <div className="fw-bold text-center">今日手机总点击</div>
              <div className="fw-bold text-center">{viewTys[1]}</div>
            </ListGroup.Item>
            <ListGroup.Item className="align-items-start">
              <div className="fw-bold text-center">今日链接总点击</div>
              <div className="fw-bold text-center">{viewTys[2]}</div>
            </ListGroup.Item>
    </ListGroup>
          
          <Button className="w-100 mt3" style={{marginTop: '15px', alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToDataAnalytic() }>数据分析</Button>
          <Button className="w-100 mt3" style={{marginTop: '15px', alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> {
            let path = '/org/' + post.id; 
            window.location.href = path;
          } }>查看网页</Button>

            </Tab.Pane>

            <Tab.Pane eventKey="#showdata">
              
            <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>网页展示</Card.Text>
            <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold text-center">公司标志，介绍和链接</div>
              <DropdownButton
                  alignRight
                  title="已展示"
                  id="dropdown-menu-align-right"
                  variant="dark"
                >
                </DropdownButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold text-center">动态</div>
              <DropdownButton
                  alignRight
                  title="未展示"
                  id="dropdown-menu-align-right"
                  variant="dark"
                >
                  <Dropdown.Item eventKey='activated'>展示</Dropdown.Item>
                  <Dropdown.Item eventKey='stop'>停止</Dropdown.Item>
                </DropdownButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold text-center">文章</div>
              <DropdownButton
                  alignRight
                  title="未展示"
                  id="dropdown-menu-align-right"
                  variant="dark"
                >
                  <Dropdown.Item eventKey='activated'>展示</Dropdown.Item>
                  <Dropdown.Item eventKey='stop'>停止</Dropdown.Item>
                </DropdownButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold text-center">活动</div>
              <DropdownButton
                  alignRight
                  title="未展示"
                  id="dropdown-menu-align-right"
                  variant="dark"
                >
                  <Dropdown.Item eventKey='activated'>展示</Dropdown.Item>
                  <Dropdown.Item eventKey='stop'>停止</Dropdown.Item>
                </DropdownButton>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start">
              <div className="fw-bold text-center">报价</div>
              <DropdownButton
                  alignRight
                  title="未展示"
                  id="dropdown-menu-align-right"
                  variant="dark"
                >
                  <Dropdown.Item eventKey='activated'>展示</Dropdown.Item>
                  <Dropdown.Item eventKey='stop'>停止</Dropdown.Item>
                </DropdownButton>
            </ListGroup.Item>

            
              
            </ListGroup>
            
          <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> {
            let path = '/org/' + post.id; 
            window.location.href = path;
          } }>查看网页</Button>
          

            </Tab.Pane>

            <Tab.Pane eventKey="#links">
              <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的链接</Card.Text>
          
          <ListGroup>
            {

              orgLinks.map(linkItm => {
                return <ListGroup.Item className="align-items-start">
                <div className="fw-bold" style={{marginBottom: '15px'}}>{linkItm.name}</div>
                <div className="fw-normal">{linkItm.url}</div>
            </ListGroup.Item>
              })

            }
          </ListGroup>
          <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeChangeGroupInfo() }>查看公司信息和链接</Button>


            </Tab.Pane>

            <Tab.Pane eventKey="#team">
              
            <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的团队</Card.Text>
          <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="fw-bold">我的账户</div>
            <div className="fw-bold">{auth.currentUser.email}</div>
          </ListGroup.Item>
          </ListGroup>
          <Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeChangeGroupInfo() }>团队信息</Button>
            </Tab.Pane>
            <Tab.Pane eventKey="#posts">
          <Card.Text className="text-center" style={{ fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的动态</Card.Text>
          <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="fw-bold">示例动态</div>
            <div className="fw-bold">查看和修改动态</div>
          </ListGroup.Item>
            {
              orgPosts.map(postItm => {
                return <ListGroup.Item className="d-flex justify-content-between align-items-start" onClick={()=>{
                  window.open("https://letjoy.app/post/"+postItm.id)
                }}>
                        <div className="fw-bold">{postItm.postContent}</div>
                        <div className="fw-bold">发布于{postItm.timestamp.toDate().toLocaleDateString("zh")}</div>
                        </ListGroup.Item>
              })

            }
          </ListGroup>
          <Row md={2} style={{ marginTop: "25px"}}>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToCreatePost() }>发布动态</Button></Col>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToPost() }>我的动态</Button></Col>
          </Row>
            </Tab.Pane>

            <Tab.Pane eventKey="#experiences">
          <Card.Text className="text-center" style={{fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的文章</Card.Text>
          
          <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="fw-bold">文章</div>
            <div className="fw-bold">查看和修改文章</div>
          </ListGroup.Item>
            {
              orgExps.map(expItm => {
                return <ListGroup.Item className="align-items-start" onClick={()=>{
                  window.open("https://letjoy.app/exp/"+expItm.id)
                }}>
                        <div className="fw-bold">{expItm.title}</div>
                        <div className="fw-normal" style={{marginTop: '15px'}}>阅读量 {expItm.viewCount} 发布于{expItm.timestamp.toDate().toLocaleDateString("zh")}</div>
                        </ListGroup.Item>
              })

            }
          </ListGroup>
          <Row md={2} style={{ marginTop: "25px"}}>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToCreateExp() }>写文章</Button></Col>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToExps() }>我的文章</Button></Col>
          </Row>
            </Tab.Pane>

            <Tab.Pane eventKey="#events">
          <Card.Text className="text-center" style={{fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的活动</Card.Text>
          
          <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="fw-bold">没有活动</div>
            <div className="fw-bold">点击创建活动</div>
          </ListGroup.Item>
            
          </ListGroup>

          <Row md={2} style={{ marginTop: "25px"}}>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToCreateEvent() }>创建活动</Button></Col>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> routeToEvents() }>我的活动</Button></Col>
          </Row>
            </Tab.Pane>

            <Tab.Pane eventKey="#quotes">
          <Card.Text className="text-center" style={{fontSize: "20px",  fontWeight: "bold", color: "#000000", padding:"8px", backgroundColor: "#ffffff", borderRadius: "12px"}}>我的报价</Card.Text>
          
          <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between align-items-start">
            <div className="fw-bold">报价清单</div>
            <div className="fw-normal">{orgQuotes.length}个项目表单</div>
          </ListGroup.Item>
          {
            orgQuotes.map((quoItm) => {
            })
          }
          
          </ListGroup>

          <Row md={2} style={{ marginTop: "25px"}}>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> {} }>添加报价项目</Button></Col>
            <Col className="d-flex justify-content-center"><Button className="w-100 mt3" style={{alignItems:'center', justifyContent:'space-between', backgroundColor:'#000000', borderRadius:'25px', borderColor:'#ffffff', borderWidth:'3px', fontSize: '20px', fontWeight:'bold', height: '50px', marginTop: "25px", marginBottom: "15px"}} onClick={()=> {routeToQuotes()} }>查看报价反馈</Button></Col>
          </Row>
            </Tab.Pane>

          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>

            

          

          
          

          


            </Card.Body>
            </Card>
            </div>
            </Container>
            </>
      }
      else{
          if (error == "NotFound") {
              return <Navigate to="/404"/>
          }
          else if (error == "NoAccess"){
              return <Navigate to="/404"/>
          }
      }
      
    })}

      
      
      </div>
   )
}