import React, { useEffect, useRef, useState } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import {isMobile} from 'react-device-detect';



export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const maxW = isMobile ? "100%" : "30%"

  const coverImgURL = "url(https://images.unsplash.com/photo-1610730297006-ed0e847c34f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)"
  
  async function handleNotificationSent() {
    // POST request using fetch with async/await
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization":"key="+process.env.REACT_APP_FIREBASE_SERVER_KEY },
        body: JSON.stringify({to: '/topics/'+process.env.REACT_APP_FIREBASE_NOTIFICATION_TO, notification: {body: '乐源开放平台登录',title:'用户登录' + emailRef.current.value, sound: 'default'}})
    };
    const response = await fetch('https://fcm.googleapis.com/fcm/send', requestOptions);
    const data = await response.json();
}


  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      handleNotificationSent()
      navigate("/dashboard")
    } catch {
      setError("无法登录，请稍后重试")
    }

    setLoading(false)
  }

  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw", backgroundImage: coverImgURL }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">登录乐源</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label>电子邮箱</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" style={{marginTop: '15px'}}>
              <Form.Label>密码</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit" style={{marginTop: '15px'}}>
              登录
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">忘记密码?</Link>
          </div>
        </Card.Body>
      </Card>
      
      </div>
    </Container>
    </>
  )
}
