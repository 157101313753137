import React, { useRef, useState, useCallback, useEffect } from "react"
import { Form, Button, Card, Alert, Container, DropdownButton, Dropdown, Modal } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useNavigate } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import { fires, auth } from "../firebase";
import firebase from 'firebase/app';
import 'firebase/firestore';
import uuid from "react-uuid";


export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const orgIdRef = useRef()
  const orgNameRef = useRef()
  const orgDescriptionRef = useRef()
  const orgTypeCustomRef = useRef()
  const { signup } = useAuth()
  const [orgTypeCN, setOrgTypeCN] = useState("未选择")
  const [orgType, setOrgType] = useState("-")
  const [error, setError] = useState("")
  const [orgId, setOrgId] = useState(uuid())
  const [loading, setLoading] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate()
  const maxW = isMobile ? "100%" : "60%"

  const [value,setValue]=useState('选择下列类型');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const coverImg = "https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80"
  const profileImg = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"

  const orgTypes = {"realtor":"地产经纪",
  "accounting":"会计师",
  "morgage":"贷款经纪",
  "immigration":"移民顾问",
  "issurance":"保险经纪",
  "financial":"理财顾问",
  "restaurant":"餐饮服务",
  "travel":"旅游资讯",
  "software":"软件公司",
  "cleaning":"清洁公司",
  "other":"其他"}


  const handleSelect=(e)=>{
    setOrgTypeCN(orgTypes[e])
    setOrgType(e)
    if (e == "other"){
      setShow(true)
    }
  }

  
  async function handleNotificationSent() {
    // POST request using fetch with async/await
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', "Authorization":"key="+process.env.REACT_APP_FIREBASE_SERVER_KEY },
        body: JSON.stringify({to: '/topics/'+process.env.REACT_APP_FIREBASE_NOTIFICATION_TO, notification: {body: '乐源开放平台'+emailRef.current.value,title:'🎉新用户注册成功' + emailRef.current.value, sound: 'default'}})
    };
    const response = await fetch('https://fcm.googleapis.com/fcm/send', requestOptions);
    const data = await response.json();
}
  

  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("密码输入不一致")
    }
    const orgId = orgIdRef.current.value
    const orgName = orgNameRef.current.value
    const orgDescription = orgDescriptionRef.current.value
    const emailA = emailRef.current.value


    
    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var dataSet = {
          "name":orgName,
          "description":orgDescription,
          "id":orgId,
          "profileURL":profileImg,
          "coverURL":coverImg,
          "fromUID":"",
          "expTitle":"",
          "postTitle":"",
          "location":"-",
          "country":"-",
          "typeCN":orgTypeCN,
          "type":orgType,
          "live":true,
          "liveAt":timestampNow,
          "status":"pending",
          "timestamp":timestampNow}

    setOrgId(orgIdRef.current.value)

    if (orgId != ""){
      try {
        setError("")
        setLoading(true)

        if (!(await fires.collection('groups').doc(orgId).get()).exists){
          await signup(emailRef.current.value, passwordRef.current.value)
          const uid = auth.currentUser.uid
          dataSet.fromUID = uid
          fires.collection('groups').doc(orgId).set(dataSet)
          fires.collection('users').doc(uid).set({"userName":orgName,"orgName":orgName,"orgId":orgId,"email":emailA}).then((error) => {
            if (error != null){

            }
            handleNotificationSent()
            navigate("/dashboard")
          })
          
        }
        else{
          setError("机构ID已经存在，请重新生成")
        }
      } catch {
        setError("无法创建账户")
      }

    }
    else{
      setError("")
      setLoading(true)
      try {
        await signup(emailRef.current.value, passwordRef.current.value)
          const uid = auth.currentUser.uid
          dataSet.fromUID = uid
          var groupRef = fires.collection('groups').add(dataSet)
          orgId = (await groupRef).id
          fires.collection('users').doc(uid).set({"userName":orgName,"orgName":orgName,"orgId":orgId,"email":emailA})
          
          navigate("/dashboard")
      } catch {
        setError("无法创建账户")
      }
    }
    
    setLoading(false)
  }

  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>
      <Card className="border-0" style={{ marginTop: '35px', marginBottom: '35px'}}>
        <Card.Body>
          <h2 className="text-center mb-4">创建乐源账户</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label>电子邮箱(必填)</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password" style={{marginTop: '15px'}}>
              <Form.Label>输入密码(必填)</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-repeat" style={{marginTop: '15px'}}>
              <Form.Label>再次输入密码(必填)</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Form.Group id="exp-title" style={{marginTop: '15px'}}>
                <Form.Label>公司类型(必填)</Form.Label>
                <DropdownButton
                  variant="dark"
                  alignRight
                  title={orgTypeCN}
                  id="dropdown-menu-align-right"
                  onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey='realtor'>{orgTypes.realtor}</Dropdown.Item>
                    <Dropdown.Item eventKey='accounting'>{orgTypes.accounting}</Dropdown.Item>
                    <Dropdown.Item eventKey='morgage'>{orgTypes.morgage}</Dropdown.Item>
                    <Dropdown.Item eventKey='immigration' >{orgTypes.immigration}</Dropdown.Item>
                    <Dropdown.Item eventKey='issurance'>{orgTypes.issurance}</Dropdown.Item>
                    <Dropdown.Item eventKey='financial'>{orgTypes.financial}</Dropdown.Item>
                    <Dropdown.Item eventKey='restaurant'>{orgTypes.restaurant}</Dropdown.Item>
                    <Dropdown.Item eventKey='travel'>{orgTypes.travel}</Dropdown.Item>
                    <Dropdown.Item eventKey='software'>{orgTypes.software}</Dropdown.Item>
                    <Dropdown.Item eventKey='cleaning'>{orgTypes.cleaning}</Dropdown.Item>
                    <Dropdown.Item eventKey='other'>{orgTypes.other}</Dropdown.Item>
              </DropdownButton>
              <Form.Label>{orgTypeCN}</Form.Label>
            </Form.Group>
            <Form.Group id="org-id" style={{marginTop: '15px'}}>
              
              <div className="d-flex justify-content-between align-items-start" style={{marginTop: '5px', marginBottom: '15px'}}>
              <Form.Label>公司ID(可自动生成)(必填)</Form.Label>
              <Button variant="dark" onClick={()=>{
                const nextUUID = uuid()
                orgIdRef.current.value = nextUUID
              }}>生成ID</Button>
              </div>
              <Form.Control as="textarea" ref={orgIdRef} rows={1} maxLength={25} required/>
            </Form.Group>
            <Form.Group id="org-name" style={{marginTop: '15px'}}>
              <Form.Label>公司名称(必填)</Form.Label>
              <Form.Control as="textarea" ref={orgNameRef} rows={1} maxLength={25} required />
            </Form.Group>
            <Form.Group id="org-description" style={{marginTop: '15px'}}>
              <Form.Label>公司简介(必填)</Form.Label>
              <Form.Control as="textarea" ref={orgDescriptionRef} rows={3} maxLength={100}/>
            </Form.Group>
            <Button variant="dark" disabled={loading} className="w-100" type="submit" style={{marginTop: '35px', height: '50px'}}>
              创建乐源账户
            </Button>



            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>自定义机构类型</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>机构类型</Form.Label>
              <Form.Control as="textarea" ref={orgTypeCustomRef} placeholder="填写机构类型" rows={1} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            关闭
          </Button>
          <Button variant="dark" onClick={()=>{
              const orgCustomType = orgTypeCustomRef.current.value
              setOrgType("other")
              setOrgTypeCN(orgCustomType)
              
              setShow(false)
          }}>
            保存
          </Button>
        </Modal.Footer>
      </Modal>



          </Form>
        </Card.Body>
      </Card>
      </div>
      </Container>
      
    </>
  )
}
